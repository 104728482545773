window.Modal = function()  {
        const btns = document.querySelectorAll('*[data-modal]');
        btns.each(function(item){
            item.addEvent('click', function(e){
                e.preventDefault();
                var modal = document.body.createElementInside('div');
                modal.modal(item.dataset.url);
            });
        });
}

window.popup = function (url, options = {}){
    let urlCallBack = "";
    if(options.urlCallBack) urlCallBack = '?urlcallback=' + options.urlCallBack;
    var modal = document.body.createElementInside('div').modal(url + urlCallBack);
    if(options.skin) modal.modal.addClass(options.skin);
    if(options.title) modal.h1.innerText = options.title;
}

Element.prototype.modal = function ($url, $options = {}){
    const $this = this;
    this.addClass('modal-cloud');
    this.modal = this.createElementInside('div').addClass('modal');
    this.header = this.modal.createElementInside('div').addClass('modal-header');
    this.h1 = this.header.createElementInside('h1').addClass('modal-title');
    const btnClose = this.modal.createElementInside('a').addClass('modal-btn-close');
    this.body = this.modal.createElementInside('div').addClass('modal-body');
    this.footer = this.modal.createElementInside('div').addClass('modal-footer');
    if($url){
        axios.get($url, {headers: {'X-Requested-With' : 'XMLHttpRequest'}})
            .then(function(response){
                $this.body.innerHTML = response.data;
                $this.body.evalScript();
            });
    }
    const destroy = function(e){
        e.preventDefault();
        $this.destroy();
    }
    btnClose.addEvent('click', destroy);
    this.addToBody = function(element){ $this.body.append(element); }

    return this;
}
